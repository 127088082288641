<template>
  <v-container
    id="register"
    style="margin-top: 45px"
    class="fill-height justify-center"
    tag="section"
  >
    <v-slide-y-transition appear>
      <v-card
        color="white"
        light
        max-width="100%"
        width="700"
        class="px-5 py-3"
        style="margin: 20px auto; display: block"
      >
        <div class="pa-2">
          <h1 class="text-center" style="font-weight: bold">
            {{ $t(`account_validation`) }}
          </h1>
          <p class="mb-0" v-if="alert">{{ $t(alert) }}</p>
          <div v-else-if="validating" class="text-center">
            <p class="mb-0">{{ $t("validating_account") }}</p>
            <v-row justify="center" align="center" class="my-2">
              <v-progress-circular
                indeterminate
                size="15"
              ></v-progress-circular>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<style lang="scss">
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "AlphaConfirmAccount",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    validating: false,
    alert: null,
    code: null,
    user: null,
  }),
  async created() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
    }
    if (this.$route.query.user) {
      this.user = this.$route.query.user;
    }
    if (!this.user || !this.code) {
      this.alert = "alpha_register_code_user_invalid";
      return;
    } else {
      this.validate_account();
    }
  },
  computed: {},
};
</script>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
